import React from 'react';
import { Grid, ButtonGroup, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {  INVOICE_ENTITY } from '../Base/Common';
import AppIcon from '../UI/Controls/AppIcons';
import { safeInt } from '../Base/Utils';

const OrderListToolbar = ({ entityName, order, handleOrderClick, printOrderHandler, openShared, index, setOrderData, setSelectedId, setSelectedReferenceId }) => {

    const history = useHistory();

    const openSharedHandler = (e) => {
        openShared(e);
        setOrderData(order);
        setSelectedId(safeInt(order.id))
        setSelectedReferenceId(order.referenceId);
    }

    const handlePaymentHandler = () => {
        history.push("/addpayment/" + order.id);
    }

    const renderToolBar = () => {
        switch (entityName) {
            case INVOICE_ENTITY:
                return (
                    <>
                        <Grid item>
                            <ButtonGroup variant="text" color="primary">
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    endIcon={<AppIcon name="Edit" size="sm" style={{ marginLeft: '-4px' }} />}
                                    style={{ background: "#fff" }}
                                    onClick={() => handleOrderClick(safeInt(order.id))}
                                >
                                    EDIT
                                </Button>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    endIcon={<AppIcon name="Print" size="sm" style={{ marginLeft: '-4px' }} />}
                                    style={{ background: "#fff" }}
                                    onClick={() => printOrderHandler(order, index)}
                                >
                                    PRINT
                                </Button>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    endIcon={<AppIcon name="Share" size="sm" style={{ marginLeft: '-4px' }} />}
                                    style={{ background: "#fff" }}
                                    onClick={(e) => openSharedHandler(e)}
                                >
                                    SHARE
                                </Button>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    endIcon={<AppIcon name="RupeeIcon" size="sm" style={{ marginLeft: '-4px' }} />}
                                    style={{ background: "#fff" }}
                                    onClick={handlePaymentHandler}
                                >
                                    Payment
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        renderToolBar()
    );
};

export default OrderListToolbar;
