import React from 'react';
import { Menu, Divider } from '@material-ui/core';
import { CREDIT_NOTE_ENTITY, DEBIT_NOTE_ENTITY, DELIVERY_CHALLAN_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY, PURCHASE_ORDER_ENTITY, QUOTATION_ENTITY } from '../Base/Common';
import { ContextOrderMenuItem, LockIcon } from '../UI/Controls/Controls';

const OrderListViewDesktopMenu = ({
    entityName,
    customerMenu,
    handleCustomerMenuClose,
    handleMenuItemClick,
    paymentAccess,
    moduleAccess,
    mobileDevice
}) => {
    const renderMenuItems = () => {
        switch (entityName) {
            case INVOICE_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit the invoice."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit invoice" iconname="Edit" />
                    </div>,
                    <Divider key="divider1" />,
                    <div
                        key="invoice-payments"
                        onClick={() => handleMenuItemClick('Payments')}
                        title="Click to add payment received against invoice."
                        style={{
                            cursor: paymentAccess.allowAdd && paymentAccess.allowEdit ? 'pointer' : 'default',
                            color: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Invoice Payments" iconname="RupeeIcon" labelcolor="success" iconSize="10px" />
                        {(!paymentAccess.allowAdd || !paymentAccess.allowEdit) && <LockIcon />}
                    </div>,
                    <Divider key="divider2" />,
                    <div
                        key="request-payment"
                        onClick={() => handleMenuItemClick('requestPayment')}
                        title="Click to send UPI payment link."
                        style={{
                            cursor: paymentAccess.allowAdd ? 'pointer' : 'default',
                            color: !paymentAccess.allowAdd ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !paymentAccess.allowAdd ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Payment Link (Beta)" iconname="RupeeIcon" labelcolor="success" />
                        {(!paymentAccess.allowAdd) && <LockIcon />}
                    </div>,
                    <Divider key="divider3" />,
                    <div
                        key="recurring"
                        onClick={() => handleMenuItemClick('Recurring')}
                        title="Click to create a Recurring invoice."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="Create Recurring" iconname="Recurring" />
                    </div>,
                    <Divider key="divider4" />,
                    <div
                        key="copy"
                        onClick={() => handleMenuItemClick('Copy')}
                        title="Click to copy & create a new sales invoice with the same item list."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];
            case PURCHASE_BILL_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit" iconname="Edit" />
                    </div>,
                    <Divider key="divider5" />,
                    <div
                        key="payments"
                        onClick={() => handleMenuItemClick('BillPayable')}
                        title="Click to add payment received against Purchase Bill."
                        style={{
                            cursor: paymentAccess.allowAdd && paymentAccess.allowEdit ? 'pointer' : 'default',
                            color: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Payments" iconname="RupeeIcon" labelcolor="success" iconSize="10px" />
                        {(!paymentAccess.allowAdd || !paymentAccess.allowEdit) && <LockIcon />}
                    </div>,
                    <Divider key="divider6" />,
                    // Removed refund option based on commented code
                    <div
                        key="copy"
                        onClick={() => handleMenuItemClick('Copy')}
                        title="Click to copy & create a new purchase bill with the same item list."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];
            case QUOTATION_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit" iconname="Edit" />
                    </div>,
                    <Divider key="divider7" />,
                    <div
                        key="copy"
                        onClick={() => handleMenuItemClick('Copy')}
                        title="Click to copy & create a new quotation with the same item list."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>,
                    <Divider key="divider16" />,
                    <div
                        key="convert"
                        onClick={() => handleMenuItemClick('Convert')}
                        title="Click to Convert to Invoice."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Convert to invoice" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>,
                    <Divider key="divider17" />,
                    <div
                        key="convert"
                        onClick={() => handleMenuItemClick('convertToDC')}
                        title="Click to Convert to Delivery Challan."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Convert to D.C. " iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider17" />,
                    <div
                        key="convert"
                        onClick={() => handleMenuItemClick('ConvertToSalesOrder')}
                        title="Click to Convert to Sales Order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Convert to S.O. " iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];
            case DELIVERY_CHALLAN_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit" iconname="Edit" />
                    </div>,
                    <Divider key="divider9" />,
                    <div
                        key="copy"
                        onClick={() => handleMenuItemClick('Copy')}
                        title="Click to copy & create a new quotation with the same item list."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>,
                    <Divider key="divider10" />,
                    <div
                        key="convert"
                        onClick={() => handleMenuItemClick('Convert')}
                        title="Click to Convert to Invoice."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Convert" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];

            case DEBIT_NOTE_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit" iconname="Edit" />
                    </div>,
                    <Divider key="divider11" />,
                    <div
                        key="refund"
                        onClick={() => handleMenuItemClick('Refund')}
                        title="Refund"
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Refund" iconname="Undo" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];
            case CREDIT_NOTE_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit" iconname="Edit" />
                    </div>,
                    <Divider key="divider12" />,
                    <div
                        key="refund"
                        onClick={() => handleMenuItemClick('Refund')}
                        title="Refund"
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Refund" iconname="Undo" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];
            case PURCHASE_ORDER_ENTITY:
                return [
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit."
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMenuItem label="View/Edit" iconname="Edit" />
                    </div>,
                    <Divider key="divider13" />,
                    <div
                        key="convert"
                        onClick={() => handleMenuItemClick('ConvertToPO')}
                        title="Convert P.O. to purchase bill"
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Convert" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>,
                    <Divider key="divider14" />,
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('fullfillmentReport')}
                        title="Fullfillment Report"
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Fullfillment Report" iconname="ReportsIcon" />
                    </div>,
                    <Divider key="divider15" />,
                    <div
                        key="copy"
                        onClick={() => handleMenuItemClick('Copy')}
                        title="Click to copy & create a new quotation with the same item list."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                    ,
                    <Divider key="divider4" />,
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                ];
            default:
                return null;
        }
    };

    return (
        <Menu
            anchorEl={customerMenu}
            keepMounted
            open={Boolean(customerMenu)}
            onClose={handleCustomerMenuClose}
            elevation={1}
            getContentAnchorEl={!mobileDevice ? null : undefined}
            anchorOrigin={!mobileDevice ? {
                vertical: 'bottom',
                horizontal: 'center',
            } : undefined}
            transformOrigin={!mobileDevice ? {
                vertical: 'top',
                horizontal: 'center',
            } : undefined}
        >
            {renderMenuItems()}
        </Menu>
    );
};

export default OrderListViewDesktopMenu;
