import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getBatchDateDisplay, ifNullOrEmpty, isEmptyObjectArray, isNullOrEmpty, isNullOrUndefined, lowerCaseFirstLetter, safeBool, safeFloat, safeInt, safeString } from '../../../Base/Utils';
import { CREDIT_NOTE_ENTITY, DEBIT_NOTE_ENTITY, DEFAULT_CREDITNOTE_VOUCHER_SETTINGS, DEFAULT_DEBITNOTE_VOUCHER_SETTINGS, DEFAULT_QUOTATION_VOUCHER_SETTINGS, DEFAULT_VOUCHER_COLUMN_DEFINITION, INVOICE_COPY_HEADINGS, INVOICE_ENTITY, INVOICE_FORMAT_PROFESSIONAL, PDF_OUTPUT_BUFFER, PDF_OUTPUT_DOWNLOAD, PDF_OUTPUT_PRINT, PDF_OUTPUT_URL, QUOTATION_ENTITY, TAX_REG_COMPOSITION_SCHEME } from '../../../Base/Common';
import { getPDFPageMargin, getPDFStyles } from './Professional/Styles';
import { getCompanyDetails, getEntityDetails, getFooter, getFooterSummary, getLogo, getOrderSummary, getPDFPageBorder, getPartyDetails, getQuotationEntityDetails, getVoucherHeading, headerDetails } from './Professional/Professional';
import { getPDFDocumentInfo, getWaterMark, buildItemsTable, getGSTINNumber } from './ProcessPDFHelper';
import { DEFAULT_VOUCHER_SETTINGS } from './../../../Base/Common';
import { getLabelText } from '../PDFHelper';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function processPrintOrder(printData, entityName, callback) {
    try {
        let voucherData = printData?.voucherData;
        var printFormatCustomSettings = null;

        var invoiceSettings;

        switch (entityName) {
            case INVOICE_ENTITY:
                invoiceSettings = DEFAULT_VOUCHER_SETTINGS;
                break;
            case QUOTATION_ENTITY:
                invoiceSettings = DEFAULT_QUOTATION_VOUCHER_SETTINGS;
                break;
            case CREDIT_NOTE_ENTITY:
                invoiceSettings = DEFAULT_CREDITNOTE_VOUCHER_SETTINGS;
                break;
            case DEBIT_NOTE_ENTITY:
                invoiceSettings = DEFAULT_DEBITNOTE_VOUCHER_SETTINGS;
                break;
            default:
                break;
        }

        var columnDefinition = DEFAULT_VOUCHER_COLUMN_DEFINITION;
        const isCompositionScheme = printData.subscriber.taxRegistrationTypeId == TAX_REG_COMPOSITION_SCHEME;
        const isEInvoice = (!isNullOrUndefined(voucherData) && !isNullOrUndefined(voucherData.eInvoiceData));

        var invoiceFormatSettings = `${lowerCaseFirstLetter(entityName)}FormatSettings`;
        var invoiceFormatColumnDefination = `${lowerCaseFirstLetter(entityName)}FormatColumnDefination`;

        if (!isNullOrEmpty(printData.settings.additionalDetails)) {
            const additionalDetailData = JSON.parse(printData.settings.additionalDetails);

            if (!isNullOrUndefined(additionalDetailData[invoiceFormatSettings]) && additionalDetailData[invoiceFormatSettings] !== "") {
                invoiceSettings = JSON.parse(additionalDetailData[invoiceFormatSettings]);
            }

            if (!isNullOrUndefined(additionalDetailData[invoiceFormatColumnDefination]) && additionalDetailData[invoiceFormatColumnDefination] !== "") {
                columnDefinition = JSON.parse(additionalDetailData[invoiceFormatColumnDefination]);
            }

            printFormatCustomSettings = {
                logoWidth: invoiceSettings.logoWidth,
                borderColor: invoiceSettings.borderColor,
                borderThickness: invoiceSettings.borderThickness,
                fontBold: invoiceSettings.fontBold,
                fontMainHeading1: invoiceSettings.fontMainHeading1,
                fontMainHeading1Color: invoiceSettings.fontMainHeading1Color,
                fontMainHeading2: invoiceSettings.fontMainHeading2,
                fontMainHeading2Color: invoiceSettings.fontMainHeading2Color,
                fontSubHeading1: invoiceSettings.fontSubHeading1,
                fontSubHeading1Color: invoiceSettings.fontSubHeading1Color,
                fontSubHeading2: invoiceSettings.fontSubHeading2,
                fontSubHeading2Color: invoiceSettings.fontSubHeading1Color,
                fontRegular: invoiceSettings.fontRegular,
                fontRegularColor: invoiceSettings.fontRegularColor,
                fontHyperLink: invoiceSettings.fontHyperLink,
                fontHyperLinkColor: invoiceSettings.fontHyperLinkColor,
                backgroundFillColor: invoiceSettings.backgroundFillColor,
                drawPageOutlineBorder: invoiceSettings.drawPageOutlineBorder === "1" ? true : false,
                pageOrientation: invoiceSettings.pageOrientation,
                itemSize: invoiceSettings.itemSize,
                hideFooter: safeBool(invoiceSettings?.hideFooter, false),
                hideHeader: safeBool(invoiceSettings?.hideHeader, false),
                footerMargin: safeInt(invoiceSettings?.footerMargin, 240), // default = 240, min = 10, max = 450
                headerMargin: safeInt(invoiceSettings?.headerMargin, 30),  // default = 30, min = 30, max = 400
                repeatFooter: safeBool(invoiceSettings?.repeatFooter, true),
                showTotalQuantityDescription: safeBool(additionalDetailData.showTotalQuantityDescription, true)
            };
        }

        if (isNullOrUndefined(printFormatCustomSettings)) {
            printFormatCustomSettings = invoiceSettings
        }

        let taxableTotalIndex = columnDefinition.findIndex(x => x.field === "taxableAmount");
        let lineTotalIndex = columnDefinition.findIndex(x => x.field === "lineTotal");
        var totalPages = 1;
        let docDefintion = {};

        const formatKey = entityName === CREDIT_NOTE_ENTITY || entityName === DEBIT_NOTE_ENTITY
            ? `${lowerCaseFirstLetter(entityName)}Format`
            : `${entityName.toLowerCase()}Format`;

        const printFormat = safeString(printData.settings[formatKey]);


        let logoWidth = safeInt(printFormatCustomSettings?.logoWidth, 100) + 10;
        if (isNullOrEmpty(printData?.subscriber?.logo)) {
            logoWidth = 0
        }

        if (safeBool(printData.settings.useBatchSerialNoTracking)) {
            for (let i = 0; i < printData.voucherItems.length; i++) {
                let item = printData.voucherItems[i];
                if (!isNullOrUndefined(item.batchItems) && !isEmptyObjectArray(item.batchItems) && item.batchItems.length > 0) {
                    item.batchNumber = safeString(item.batchItems[0].batchNumber);
                    item.mfgDate = getBatchDateDisplay(item.batchItems[0].mfgDate);
                    item.expiryDate = getBatchDateDisplay(item.batchItems[0].expiryDate);
                }
            }
        }

        let customerDetails = [];

        customerDetails.push([
            getPartyDetails(printData, printFormatCustomSettings, entityName), // Cell for BILL TO address                                                        
            entityName === INVOICE_ENTITY || entityName === CREDIT_NOTE_ENTITY || entityName === DEBIT_NOTE_ENTITY ? getEntityDetails(printData, printFormatCustomSettings, entityName) : getQuotationEntityDetails(printData, printFormatCustomSettings, entityName) // Cell for Entity Details & Ship to Address
        ]);



        if (isEInvoice) {
            let irnDetails = safeString(voucherData.eInvoiceData.irnNo) + " Ack. & Dt: " + safeString(voucherData.eInvoiceData.ackNo + " " + voucherData.eInvoiceData.ackDate);
            customerDetails.push(
                [
                    { text: ["IRN: ", { text: irnDetails, bold: false }], colSpan: 2, alignment: 'center', fontSize: 9, bold: true }
                ]
            )
        }

        let mainBody = [
            [
                [
                    {
                        stack: [safeBool(printFormatCustomSettings?.hideHeader) ? null : getLogo(printData.subscriber, printFormatCustomSettings)]
                    },
                ],
                [
                    safeBool(printFormatCustomSettings?.hideHeader) ? null : getCompanyDetails(printData, printFormatCustomSettings)
                ]
            ],
            // Row 2: Tax ID Number Details
            [
                {
                    style: 'nestedTable',
                    colSpan: 2,
                    table: {
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                [getGSTINNumber(printData)],
                                [getVoucherHeading(printData, printFormatCustomSettings)],
                                ['']
                            ]
                        ]
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return printFormatCustomSettings.backgroundFillColor; // Set the header row's background color
                        },
                        hLineWidth: function (i, node) {
                            return 0;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                        },
                        hLineColor: function (i, node) {
                            return printFormatCustomSettings.borderColor;
                        },
                        vLineColor: function (i, node) {
                            return printFormatCustomSettings.borderColor;
                        },
                        padding: function (i, node) { return 0; }
                    }
                }
            ],
            // Row 3: Customer Details & Entity (Order) details
            [
                {
                    style: 'nestedTable',
                    colSpan: 2,
                    table: {
                        widths: ['40%', '60%'],
                        body: customerDetails,
                        margin: [0, 0, 0, 0],
                        padding: [0, 0, 0, 0]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 1;
                        },
                        vLineWidth: function (i, node) {
                            return 1;
                        },
                        hLineColor: function (i, node) {
                            return printFormatCustomSettings.borderColor;
                        },
                        vLineColor: function (i, node) {
                            return printFormatCustomSettings.borderColor;
                        },
                        paddingTop: function (i, node) { return 0; },
                        paddingBottom: function (i, node) { return 0; },
                        paddingRight: function (i, node) { return 0; },
                        paddingLeft: function (i, node) { return 0; }
                    }
                }
            ],
            // Row 4: Voucher Items
            [
                {
                    style: 'nestedTable',
                    colSpan: 2,
                    dontBreakRows: true, // Prevent row breaking                                        
                    table: buildItemsTable(columnDefinition, printData.voucherData, printData.voucherItems, true, printFormatCustomSettings),
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            if (rowIndex === 0 || rowIndex === node.table.body.length - 1) {
                                return printFormatCustomSettings.backgroundFillColor;
                            }
                            if (columnIndex === lineTotalIndex || columnIndex === taxableTotalIndex) {
                                return printFormatCustomSettings.backgroundFillColor;
                            }
                            return null;
                        },
                        paddingBottom: (rowIndex, node) => {
                            const DEFAULT_PADDING = 1;
                            if (!safeBool(printFormatCustomSettings.repeatFooter)) {
                                // Calculate padding for the last element of the table.
                                if (rowIndex === node.table.body.length - 2) {
                                    const currentPosition = node.positions[node.positions.length - 1];
                                    const currentHeight = currentPosition.top;
                                    let paddingBottom = DEFAULT_PADDING;
                                    if (currentHeight < (currentPosition.pageInnerHeight * 0.56)) {
                                        paddingBottom = (currentPosition.pageInnerHeight * 0.56) - currentHeight;
                                    }
                                    return paddingBottom;
                                } else {
                                    return DEFAULT_PADDING;
                                }
                            } else {
                                // Calculate padding for the last element of the table.
                                if (rowIndex === node.table.body.length - 2) {
                                    const currentPosition = node.positions.reduce(function (prev, current) {
                                        return (prev && (prev.top > current.top && prev.pageNumber === 1)) ? prev : current
                                    });
                                    let calculatedPadding = (currentPosition.pageInnerHeight) - (currentPosition.top);
                                    if (calculatedPadding > 0 && currentPosition.pageNumber === 1) {
                                        if (isCompositionScheme) {
                                            calculatedPadding -= 10;
                                        }
                                        return calculatedPadding;
                                    } else {
                                        return DEFAULT_PADDING;
                                    }
                                } else {
                                    return DEFAULT_PADDING;
                                }
                            }

                        },
                        hLineWidth: function (i, node) {
                            return (i === 1 || i === node.table.body.length || i === node.table.body.length - 1) ? 1 : 0;
                        },
                        vLineWidth: function (i, node) {
                            return 1;
                        },
                        hLineColor: function (i, node) {
                            return printFormatCustomSettings.borderColor;
                        },
                        vLineColor: function (i, node) {
                            return printFormatCustomSettings.borderColor;
                        }
                    }
                }
            ],
        ];

        if (isCompositionScheme) {
            mainBody.push([
                {
                    style: 'nestedTable',
                    colSpan: 2,
                    text: getLabelText("Declaration: ", "Composition taxable person, not eligibile to collect tax on supplies.", true, 10, 'center'), margin: [0, 4, 0, 0], alignment: 'center', fontSize: 10
                }
            ]);
        }

        if (!safeBool(printFormatCustomSettings.repeatFooter)) {
            var footerDefinition = {
                // style: 'footerTable',
                style: 'nestedTable',
                colSpan: 2,
                table: {
                    widths: ['65%', '35%'],
                    body: [
                        [
                            getFooterSummary(printData.settings, printData.bankDetails, printFormatCustomSettings, printData.voucherData, entityName),
                            getOrderSummary(printData, printFormatCustomSettings)
                        ],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        return printFormatCustomSettings.borderColor;
                    },
                    vLineColor: function (i, node) {
                        return printFormatCustomSettings.borderColor;
                    },
                    paddingTop: function (i, node) { return 0; },
                    paddingBottom: function (i, node) { return 0; },
                    paddingLeft: function (i, node) { return 0; },
                    paddingRight: function (i, node) { return 0; }
                }
            };
            mainBody.push([footerDefinition]);
        }

        switch (printFormat) {
            case INVOICE_FORMAT_PROFESSIONAL:
                docDefintion = {
                    watermark: getWaterMark(printData.settings),
                    styles: getPDFStyles(printFormatCustomSettings),
                    info: getPDFDocumentInfo(`${printData.downloadFileName}.pdf`, printData.heading, printData.heading),
                    pageSize: 'A4', // a string or { width: number, height: number }
                    pageMargins: getPDFPageMargin(printFormatCustomSettings),
                    pageOrientation: printFormatCustomSettings.pageOrientation, // landscape,  portrait       
                    background: function (currentPage, pageSize) {
                        if (!printFormatCustomSettings.drawPageOutlineBorder) {
                            if (!printFormatCustomSettings.repeatFooter) {
                                return [
                                    {
                                        canvas: []
                                    }
                                ];
                            } else {
                                return [
                                    {
                                        canvas: [
                                            //{ type: 'line', x1: 20, y1: 30, x2: 575, y2: 30, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Up line
                                            { type: 'line', x1: 20, y1: 230, x2: 20, y2: 602, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //left line
                                            // { type: 'line', x1: 20, y1: 822, x2: 575, y2: 822, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Bottom line
                                            { type: 'line', x1: 575, y1: 230, x2: 575, y2: 602, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Rigth line
                                        ]
                                    }
                                ];
                            }

                        } else {
                            return getPDFPageBorder(printFormatCustomSettings);
                        }
                    },
                    header: function (currentPage, pageCount, pageSize) {
                        return headerDetails(printData, printData.settings, entityName);
                    },
                    footer: function (currentPage, pageCount, pageSize) {
                        totalPages = pageCount;
                        return getFooter(currentPage, pageCount, printData, entityName, printFormatCustomSettings);
                    },

                    content: [
                        {
                            table: {
                                headerRows: 3,
                                widths: [logoWidth, '*'],
                                body: mainBody,
                            },
                            layout: {
                                hLineWidth: function (i, node) {
                                    return i === 1 ? 1 : 0;
                                },
                                vLineWidth: function (i, node) {
                                    return 0;
                                },
                                hLineColor: function (i, node) {
                                    return printFormatCustomSettings.borderColor;
                                },
                                vLineColor: function (i, node) {
                                    return printFormatCustomSettings.borderColor; // Customize as needed based on printFormatCustomSettings
                                },
                                paddingTop: function (i, node) { return 0; },
                                paddingBottom: function (i, node) { return 0; },
                                paddingRight: function (i, node) { return 0; },
                                paddingLeft: function (i, node) { return 0; }
                            }
                        }
                    ],
                }
                break;
            case "Prestige":
                break;
            default:
                break;
        }



        const pdfDocGenerator = pdfMake.createPdf(docDefintion);
        if (printData.outputType === PDF_OUTPUT_URL) {
            pdfDocGenerator.getBuffer(async (pdfBuffer) => {
                var blobURL = null;
                switch (entityName) {
                    case INVOICE_ENTITY:
                        blobURL = await copyPages(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, safeInt(printData.settings.invoiceCopies) + 1, false, printData.settings);
                        break;
                    case QUOTATION_ENTITY:
                        blobURL = await copyPagesWithoutHeading(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, safeInt(printData.settings.quotationCopies) + 1, false, printData.settings);
                        break;
                    case CREDIT_NOTE_ENTITY:
                        blobURL = await copyPagesWithoutHeading(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, safeInt(printData.settings.creditNoteCopies) + 1, false, printData.settings);
                        break;
                    case DEBIT_NOTE_ENTITY:
                        blobURL = await copyPagesWithoutHeading(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, safeInt(printData.settings.debitNoteCopies) + 1, false, printData.settings);
                        break;
                    default:
                        break;
                }


                callback(blobURL);
            });
        } else if (printData.outputType === PDF_OUTPUT_BUFFER) {
            pdfDocGenerator.getBase64(async (pdfBuffer) => {
                callback(pdfBuffer);
            });
        } else if (printData.outputType === PDF_OUTPUT_PRINT) {
            // var win = window.open('', '_blank');
            pdfDocGenerator.print();
        } else if (printData.outputType === PDF_OUTPUT_DOWNLOAD) {
            pdfDocGenerator.getBuffer(async (pdfBuffer) => {
                var blob = await copyPages(pdfBuffer, getFileName(printData), totalPages, safeInt(printData.downloadCopies) + 1, true, printData.settings);
                download(blob, printData.downloadFileName + '.pdf', 'application/pdf');
            });
        } else {
            pdfDocGenerator.download(printData.downloadFileName + '.pdf');
        }
    } catch (error) {
        callback({ error: 'Failed to generate PDF. Please verify the logo image you have selected is in correct format.' })
    }

}

async function copyPagesWithoutHeading(pdfBuffer, fileName, pageCount = 1, noOfCopies = 1, returnBlob = false, settings) {
    const originalDocument = await PDFDocument.load(pdfBuffer);
    const pdfDoc = await PDFDocument.create();
    for (let i = 0; i < noOfCopies; i++) {
        for (let j = 0; j < pageCount; j++) {
            const [pageToAdd] = await pdfDoc.copyPages(originalDocument, [j]);
            pdfDoc.addPage(pageToAdd);
        }
    }

    pdfDoc.setTitle(fileName);
    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    if (returnBlob) {
        return blob;
    }
    return URL.createObjectURL(blob);
}


function outputPDF(printData, docDefinition, totalPages, callback) {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === PDF_OUTPUT_URL) {
        pdfDocGenerator.getBuffer(async (pdfBuffer) => {
            var blobURL = await copyPages(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, safeInt(printData.settings.invoiceCopies) + 1, false, printData.settings);

            callback(blobURL);
        });
    } else if (printData.outputType === PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === PDF_OUTPUT_PRINT) {
        // var win = window.open('', '_blank');
        pdfDocGenerator.print();
    } else if (printData.outputType === PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.getBuffer(async (pdfBuffer) => {
            var blob = await copyPages(pdfBuffer, getFileName(printData), totalPages, printData.downloadCopies, true, printData.settings);
            download(blob, printData.downloadFileName + '.pdf', 'application/pdf');
        });
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}


async function copyPages(pdfBuffer, fileName, pageCount = 1, noOfCopies = 1, returnBlob = false, settings) {
    const originalDocument = await PDFDocument.load(pdfBuffer);

    const pdfDoc = await PDFDocument.create();

    const documentCopyHeadings = [
        ifNullOrEmpty(settings?.invoiceCopyHeading1, INVOICE_COPY_HEADINGS[0]),
        ifNullOrEmpty(settings?.invoiceCopyHeading2, INVOICE_COPY_HEADINGS[1]),
        ifNullOrEmpty(settings?.invoiceCopyHeading3, INVOICE_COPY_HEADINGS[2]),
        ifNullOrEmpty(settings?.invoiceCopyHeading4, INVOICE_COPY_HEADINGS[3]),
        ifNullOrEmpty(settings?.invoiceCopyHeading5, INVOICE_COPY_HEADINGS[4]),
    ];

    for (let i = 0; i < noOfCopies; i++) {
        for (let j = 0; j < pageCount; j++) {
            const [pageToAdd] = await pdfDoc.copyPages(originalDocument, [j]);
            pdfDoc.addPage(pageToAdd);

            const addedPage = pdfDoc.getPages()[pdfDoc.getPages().length - 1];
            const { width, height } = addedPage.getSize();
            let documentHeading = documentCopyHeadings[i] + ``;
            addedPage.drawText(documentHeading, {
                x: width - 185,
                y: height - 20,
                size: 10,
            });
        }
    }

    pdfDoc.setTitle(fileName);
    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    if (returnBlob) {
        return blob;
    }
    return URL.createObjectURL(blob);
}

function getFileName(printData) {
    return printData.voucherData.orderNumber + "_" + printData.party.legalName + ".pdf";
}

